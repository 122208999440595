<template>
  <div class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-12">
      <breadcrumbs
        :path="[
          {
            name: $t('object-types-view.heading'),
            url: { name: 'GeneralObjectTypes' },
          },
          { name: $t('add-object-type-view.add-object-type') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>

      <object-type-form
        id="object-typeForm"
        class="mb-64"
        :clear="submissionEndSuccessfully"
        @on-submit="handleSubmission"
      />
    </div>
    <footer-menu>
      <div class="flex justify-between">
        <div class="flex">
          <btn
            :is-loading="redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="object-typeForm"
            class="w-48 mr-3"
            @click="redirectAfterSubmission = true"
          >
            {{ $t('common.save') }}
          </btn>
        </div>
        <btn theme="none" @click="$router.push({ name: 'GeneralObjectTypes' })">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import ObjectTypeForm from '@/views/Dashboard/ObjectTypes/Forms/ObjectTypeForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'AddObjectType',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    ObjectTypeForm,
  },
  data() {
    return {
      redirectAfterSubmission: false,
      submissionInProgress: false,
      submissionEndSuccessfully: false,
    };
  },
  methods: {
    ...mapActions('object', ['addObjectType']),
    async handleSubmission(formData) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;
      try {
        await this.addObjectType(formData);

        this.$toast.success(this.$t('add-object-type-view.added-successfully'));
        if (this.redirectAfterSubmission)
          this.$router.push({ name: 'GeneralObjectTypes' });

        this.submissionEndSuccessfully = true;
      } finally {
        this.submissionInProgress = false;
      }
    },
  },
};
</script>
